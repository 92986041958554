<template>
  <v-dialog v-model="showingSchoolModal" width="700" scrollable persistent minHeight="1200">
    <v-form ref="schoolForm" autocomplete="new-password" @submit.prevent="createSchool">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ editFormId? $t('Editar') +' '+ $t('Escuela') : $t('Crear') +' '+ $t('Escuela') }}
        </v-card-title>
        <v-card-text style="max-height: 500px">
          <v-row class="mt-2">
            <v-col cols="6" class="mt-2">
              <v-text-field
                ref="name"
                v-model="school_form.name"
                outlined
                dense
                autocomplete="off"
                hide-details="auto"
                :rules="[() => !!school_form.name || 'Campo requerido']"
                :label="$t('Nombre de la Escuela')"
                />
            </v-col>

            <v-col cols="6" class="mt-2">
              <v-text-field
                ref="project_name"
                v-model="school_form.project_name"
                outlined
                dense
                autocomplete="off"
                hide-details="auto"
                :rules="[() => !!school_form.project_name || 'Campo requerido']"
                :label="$t('Nombre del proyecto')"
                />
            </v-col>

            <v-col cols="6" md="6" >
              <v-text-field
                ref="email"
                v-model="school_form.email"
                outlined
                dense
                autocomplete="off"
                hide-details="auto"
                label="Email"
              />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                  ref="phone"
                  v-model="school_form.phone"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details="auto"
                  :label="$t('Teléfono')"
                />
            </v-col>


            <v-col cols="6">
              <v-select
                :items="modalityItems"
                hide-details="true"
                v-model="school_form.modality"
                :label="$t('Modalidad')"
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                :items="managementItems"
                hide-details="true"
                v-model="school_form.type_of_management"
                :label="$t('Tipo de gestión')"
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                :items="levelItems"
                hide-details="true"
                v-model="school_form.level"
                :label="$t('Nivel')"
              ></v-select>
            </v-col>

            <v-col cols="6" v-if="getUserRole !== 3">
              <v-select
                :items="regionList"
                hide-details="true"
                v-model="school_form.region"
                :rules="[() => typeof school_form.region === 'number' || $t('Campo requerido')]"
                :label="$t('Region')"
                item-text="name"
                item-value="id"
              ></v-select>
            </v-col>


            <v-col cols="6" md="6">
              <v-text-field
                ref="number_of_students"
                v-model="school_form.number_of_students"
                outlined
                type="number"
                dense
                autocomplete="off"
                hide-details="auto"
                :label="$t('Cantidad de estudiantes')"
                />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                ref="students_with_disabilities"
                v-model="school_form.students_with_disabilities"
                outlined
                type="number"
                dense
                autocomplete="off"
                hide-details="auto"
                :label="$t('Estudiantes con discapacidades')"
                />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                ref="students_with_mult_disabilities"
                v-model="school_form.students_with_mult_disabilities"
                outlined
                type="number"
                dense
                autocomplete="off"
                hide-details="auto"
                :label="$t('Estudiantes con multiple discapacidades')"
                />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                ref="students_with_visual_disabilities"
                v-model="school_form.students_with_visual_disabilities"
                outlined
                type="number"
                dense
                autocomplete="off"
                hide-details="auto"
                :label="$t('Estudiantes con discapacidades visuales')"
                />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                ref="students_with_deafblindness"
                v-model="school_form.students_with_deafblindness"
                outlined
                type="number"
                dense
                autocomplete="off"
                hide-details="auto"
                :label="$t('Estudiantes con sordoceguera')"
                />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                ref="number_of_teachers"
                v-model="school_form.number_of_teachers"
                outlined
                type="number"
                dense
                autocomplete="off"
                hide-details="auto"
                :label="$t('Cantidad de profesores')"
                />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                ref="number_of_other_professionals"
                v-model="school_form.number_of_other_professionals"
                outlined
                type="number"
                dense
                autocomplete="off"
                hide-details="auto"
                :label="$t('Cantidad de otros profesionales')"
                />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                ref="total_staff"
                v-model="school_form.total_staff"
                outlined
                type="number"
                dense
                autocomplete="off"
                hide-details="auto"
                :label="$t('Personal total')"
                />
            </v-col>

            <v-col cols="12" class="mt-2">
              <v-text-field
                ref="professions"
                v-model="school_form.professions"
                outlined
                dense
                autocomplete="off"
                hide-details="auto"
                :label="$t('Profesiones')"
                />
            </v-col>

          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="tertiary" 
            outlined 
            @click="close()"
          >
            {{$t('Cancelar')}}
          </v-btn>
          <v-btn color="primary" type="submit">
            {{ editFormId ? $t('Editar') +' '+ $t('Escuela') : $t('Crear') +' '+ $t('Escuela') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { useUtils } from '@core/libs/i18n'

export default {
  data: () => {
    return {
      editFormId: null,
      regionList: [],
      showingSchoolModal: false,
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || false;
        },
      },
      school_form: {
        name:'',
        project_name: '',
        email: '',
        phone: null,
        modality: null,
        type_of_management: null,
        level: null,
        number_of_students: null,
        students_with_disabilities: null,
        students_with_mult_disabilities: null,
        students_with_visual_disabilities: null,
        students_with_deafblindness: null,
        number_of_teachers: null,
        number_of_other_professionals: null,
        total_staff: null,
        professions: '',
        region: null
      }
    }
  },
  methods: {
    openModal(id_to_edit) {
      if(this.getUserRole !== 3)this.getRegionList()
      id_to_edit
          ? this.setItemToEdit(id_to_edit)
          : this.showingSchoolModal = true;
    },
    async setItemToEdit(id_to_edit) {
      const item = await this.$api.getSchool(id_to_edit);
      if (item) {
        this.editFormId = id_to_edit;
        this.school_form.name = item.name ?? ''
        this.school_form.project_name = item.project_name ?? ''
        this.school_form.email = item.email ?? ''
        this.school_form.phone = item.phone ?? null
        this.school_form.modality = item.modality ?? null
        this.school_form.type_of_management = item.type_of_management ?? null
        this.school_form.level = item.level ?? null
        this.school_form.number_of_students = item.number_of_students ?? null
        this.school_form.students_with_disabilities = item.students_with_disabilities ?? null
        this.school_form.students_with_mult_disabilities = item.students_with_mult_disabilities ?? null
        this.school_form.students_with_visual_disabilities = item.students_with_visual_disabilities ?? null
        this.school_form.students_with_deafblindness = item.students_with_deafblindness ?? null
        this.school_form.number_of_teachers = item.number_of_teachers ?? null
        this.school_form.number_of_other_professionals = item.number_of_other_professionals ?? null
        this.school_form.total_staff = item.total_staff ?? null
        this.school_form.professions = item.professions ?? ''
        this.school_form.region = item.region ?? null
        this.showingSchoolModal = true;
      }
    },
    async createSchool() {
        if (this.$refs.schoolForm.validate()) {
        const form = this.parseForm();
        if(this.getUserRole === 3) delete form.region;
        console.log(form);
        try {
          const response = this.editFormId
              ? await this.$api.updateSchool(this.editFormId, form)
              : await this.$api.createSchool(form);
          this.$emit("reload-school-list");
          this.close()
        }
        catch (err) {
          console.log(err);
        }
      }
    },
    close() {
      this.$refs.schoolForm.reset()
      this.editFormId = null;
      this.showingSchoolModal = false;
    },
    parseForm() {
      const formParsed = JSON.parse(JSON.stringify(this.school_form));
      return formParsed;
    },
    async getRegionList() {
      const query = { paginated: false }
      try {
        const response = await this.$api.getRegionList(query)
        this.regionList = response
      } catch(e) {
        console.log(e)
      }
    }
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    modalityItems() {
      return [
        {text: this.$t('Educación regular'), value: 0},
        {text: this.$t('Educación especial'), value: 1},
        {text: this.$t('Educación domiciliaria hospitalaria'), value: 2},
        {text: this.$t('Centro de primera infancia'), value: 3},
      ]
    },

    managementItems() {
      return [
        {text: this.$t('Público'), value: 0},
        {text: this.$t('Privado'), value: 1},
      ]
    },

    levelItems() {
      return [
        {text: this.$t('Preescolar'), value: 0},
        {text: this.$t('Escolar primario'), value: 1},
        {text: this.$t('Escolar secundario'), value: 2},
      ]
    }
  }
}
</script>
